

.sticky {
    overflow: scroll;
}
.sticky .header, .sticky .footer {
    position: sticky;
    z-index: 1;
    width: fit-content;
}
.sticky .header {
    top: 0;
    box-shadow: 0px 3px 3px #ccc;
}
.sticky .footer {
    bottom: 0;
    box-shadow: 0px -3px 3px #ccc;
}
.sticky .body {
    position: relative;
    z-index: 0;
}
.sticky [data-sticky-td] {
    position: sticky;
}
.sticky [data-sticky-last-left-td] {
    box-shadow: 2px 0px 3px #ccc;
}
.sticky [data-sticky-first-right-td] {
    box-shadow: -2px 0px 3px #ccc;
}
